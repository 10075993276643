// extracted by mini-css-extract-plugin
export var ArtistDescription = "LeeQuinones-module--ArtistDescription--ZmZhN";
export var ArtistInfos = "LeeQuinones-module--ArtistInfos--clpFU";
export var ButtonWrapper = "LeeQuinones-module--ButtonWrapper --XWEVP";
export var CardWrapper = "LeeQuinones-module--CardWrapper--yfHnd";
export var CarrouselWrapper2 = "LeeQuinones-module--CarrouselWrapper2--msiaS";
export var Citations = "LeeQuinones-module--Citations--tEP3t";
export var DescriptionWrapper = "LeeQuinones-module--DescriptionWrapper--VBEyT";
export var ImageWrapper = "LeeQuinones-module--ImageWrapper--Y138R";
export var LinkWrapper = "LeeQuinones-module--LinkWrapper--H1Lt7";
export var MobileProtrait = "LeeQuinones-module--MobileProtrait--m8S+H";
export var More = "LeeQuinones-module--More--1aiHC";
export var MoreButton = "LeeQuinones-module--MoreButton--eIq7V";
export var NameWrapper = "LeeQuinones-module--NameWrapper--Dcayc";
export var PdpWrapper = "LeeQuinones-module--PdpWrapper--aCjRZ";
export var PhotosWrapper = "LeeQuinones-module--PhotosWrapper--wa25g";
export var ProfilWrapper = "LeeQuinones-module--ProfilWrapper--vS+yk";
export var TitleWrapper = "LeeQuinones-module--TitleWrapper--kgYYj";
export var Wrapper = "LeeQuinones-module--Wrapper--KQpXz";